<template>
    <div class="mains">
        <!-- 导航部分 -->
        <div class="top_logo">
            <div class="nav">
                <el-menu text-color="#454a4d" active-text-color="#57b2d8" mode="horizontal" menu-trigger="click"
                    default-active="1">
                    <el-menu-item index="1" @click="jump('/home')">首页</el-menu-item>
                    <el-menu-item index="2" @click="jump('/comp')">公司简介</el-menu-item>
                    <el-menu-item index="3" @click="jump('/product')">产品介绍</el-menu-item>
                    <el-menu-item index="4" @click="jump('/toge')">产品特色</el-menu-item>
                    <el-menu-item index="5" @click="jump('/unique')">合作企业</el-menu-item>
                    <el-menu-item index="6" @click="jump('/relat')">联系我们</el-menu-item>
                </el-menu>
            </div>
        </div>
        <!-- 页面显示区域 -->
        <div class="content">
            <router-view></router-view>
        </div>
    </div>
</template>

<script>
export default {
    data () {
        return {
            show: true,
        }
    },
    methods: {
        handle_go (e, zs) {
            // console.log(index)
            if (e.index == '1') {
                this.show = true;
            } else {
                this.show = false;
            }
        },
    }
}
</script>

<style lang="scss" scoped>
.mains {
    width: 100%;
    height: 100%;
    .top_logo {
        // background: blue;
        width: 95%;
        // height: 12%;
        // text-align: right;
        .nav {
            float: right;
            margin-bottom: 10px;
            .el-menu {
                border-bottom: none;
                // .el-menu--horizontal {
                //     border-bottom: none;
                // }
                .el-menu-item {
                    height: 80px;
                    line-height: 80px;
                    border-bottom: none;
                    text-decoration: none;
                    border-top: 5px solid #ffffff;
                }
                .el-menu-item.is-active {
                    border-bottom: none;
                    border-top: 5px solid #57b8d8;
                }
            }
        }
    }
    .content {
        width: 100%;
        // min-height: 50%;
        height: 95%;
        // background: red;
    }
}
</style>